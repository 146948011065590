import Plugin0 from '@/components/User/routes.js';
import Plugin1 from '@/components/Patient/routes.js';
import Plugin2 from '@/components/VocalMessages/routes.js';
import Plugin3 from '@/components/HealthcareProfessional/routes.js';
import Plugin4 from '@/components/Iot/routes.js';
import Plugin5 from '@/components/VitalSigns/routes.js';
import Plugin6 from '@/components/Cohorts/routes.js';
import Plugin7 from '@/components/PatientIotRealtime/routes.js';
import Plugin8 from '@/components/Dialogue/routes.js';
import Plugin9 from '@/components/Activity/routes.js';
import Plugin10 from '@/components/Resources/routes.js';
import Plugin11 from '@/components/Notification/routes.js';
import Plugin12 from '@/components/Protocol/routes.js';

export default function getModulesRoutes() {
var modules = [];

 modules.push(Plugin0.routes);
 modules.push(Plugin1.routes);
 modules.push(Plugin2.routes);
 modules.push(Plugin3.routes);
 modules.push(Plugin4.routes);
 modules.push(Plugin5.routes);
 modules.push(Plugin6.routes);
 modules.push(Plugin7.routes);
 modules.push(Plugin8.routes);
 modules.push(Plugin9.routes);
 modules.push(Plugin10.routes);
 modules.push(Plugin11.routes);
 modules.push(Plugin12.routes);

return modules;
}
